import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import questions from '../assets/questions/grandezas_e_volumes.json';

const Exercises = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { subject, content } = location.state || {};

    // Estado para controlar a dica visível
    const [visibleHint, setVisibleHint] = useState(null);

    const toggleHint = (id) => {
        setVisibleHint(visibleHint === id ? null : id);
    };

    return( 
    <div className='questions-container'>
        <h2>{content}</h2>
        <div className='questions-list'>
            {questions.map(q => 
                <div className='question' id={q.id} key={q.id}>
                    <span className='enunciado'>
                       {q.id}. {q.enunciado}
                    </span>
                    {q.imagem ? 
                      <img 
                        src={require(`../assets/questions/images/${q.imagem}`)} 
                        alt={`imagem da questao ${q.id}`}
                      />
                      : null}
                    <div className='answer-container'>
                        <label>RESPOSTA:</label>
                        <input type='text' />
                        <span 
                            id='hint-button' 
                            className="hint-button" 
                            onClick={() => toggleHint(q.id)}
                        >
                            DICA
                        </span>
                        {visibleHint === q.id && (
                            <div id='hint-text' className='hint-text'>
                                <p>é só converter usando a tabela que aprendemos</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
        <button className="answer-button">Corrigir questoes</button>
    </div>
    );
};

export default Exercises;

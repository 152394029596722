import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header'
import Welcome from './components/Welcome';
import NotFound from './components/NotFound';
import Subjects from './components/Subjects';
import SubjectContent from './components/SubjectContent';
import Video from './components/Video';
import Exercises from './components/Exercises';


function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <div className='main-container'>
          <Routes>
            <Route path='/' element={<Welcome/> }/>
            <Route path='/home' element={<Welcome/> }/>
            <Route path='/subjects' element={<Subjects/> }/>
            <Route path='/subjectcontent' element={<SubjectContent/> }/>
            <Route path='/video' element={<Video/> }/>
            <Route path='/exercises' element={<Exercises/> }/>
            <Route path='/*' element={<NotFound/> }/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import data from '../assets/videos.json'
import rightarrow from '../assets/right-arrow.svg'

const Video = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { subject, content } = location.state || {}; 
    const item = data[subject+"-"+content]
    const youtubeUrl = `https://www.youtube.com/embed/${item}`


    const handleClick = () => {
        navigate('/exercises', { state: { subject: subject, content: content } });
    };

  return (
    <>
        <div className='next-button' onClick={() => handleClick()}>
            <span>QUESTÕES</span>
            <img src={rightarrow}></img>
        </div>
        <div className='video-container'>
            <iframe src={youtubeUrl} title="Medidas de Volume - Conversão" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </>
  );
};

export default Video;

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import matematica from '../assets/subjects/matematica.png'
import volumes from '../assets/subject-contents/matematica-volumes.png'
import areas from '../assets/subject-contents/matematica-areas.png'

const Subjects = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subject } = location.state || {}; 
  console.log(subject)

  const handleClick = (content) => {
    navigate('/video', { state: { subject: subject, content: content} });
  };


  return (
    <div className='subjects-content-container'>
        <img src={matematica} alt="matematica" />
        <div className='contents-container'>
            <div className='content'>
                <img src={volumes} alt="volume" onClick={() => handleClick("volumes")}/>
                <span>VOLUMES</span>
            </div>
            <div className='content'>
                <img src={areas} alt="area" onClick={() => handleClick("areas")} />
                <span>ÁREAS</span>
            </div>
        </div>
    </div>
  );
};

export default Subjects;

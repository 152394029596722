import { useNavigate } from 'react-router-dom';
import matematica from '../assets/subjects/matematica.png'

const Subjects = () => {
  const navigate = useNavigate();

  const handleClick = (subject) => {
    navigate('/subjectcontent', { state: { subject: subject } });
  };


  return (
    <div className='subjects-container'>
        <h2>Vamos aprender!</h2>
        <ul>
          <li className='subject-banner'>
            <img src={matematica} alt="matematica" onClick={() => handleClick('matematica')} />
          </li>
        </ul>
    </div>
  );
};

export default Subjects;

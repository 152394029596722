import logo from '../assets/logo.png';

const Header = () => {
    return (
    <div className='header' id='header'>
        <img src={logo} alt='logo'/>
        <span>FALCO</span>
    </div>
    )
}

export default Header;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  const [nome, setNome] = useState('');
  const [erro, setErro] = useState('');

  const salvarNome = (e) => {
    e.preventDefault();

    if (nome.trim()) { 
      localStorage.setItem('name', nome);
      navigate('/subjects');
      
    } else {
      setErro('Por favor, digite seu nome.');
    }
  };

  return (
    <div className='welcome-container' id='welcome'>
      <h1>Olá! Bem vindo à plataforma Falco</h1>
      <form onSubmit={salvarNome}>
        <label>Nos diga seu nome:</label>
        <input
          type="text"
          placeholder="Digite seu nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <button type="submit">Avançar</button>
        {erro && <p style={{ color: 'red' }}>{erro}</p>}
      </form>
    </div>
  );
};

export default Welcome;
